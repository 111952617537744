<template>
  <div style="background-color:#f1f1f1;">
    <el-row :gutter="20">
      <el-col :span="6">
        <div style="height:calc(100vh - 164px);overflow:auto;background-color:white;padding:16px;">
          <div v-for="(item) in list" :key="item.Id" :class="item.class" @click="showDoc(item)">
            {{ item.Title }}
          </div>
        </div>
      </el-col>
      <el-col :span="18" style="height:calc(100vh - 132px);overflow:auto;">
        <div style="background-color:white;padding:1em;width:990px;">
          <div v-html="content" style="text-align:center;background-color:white;" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { submit } from "@/api/base";
export default {
  name: "Documents",
  data() {
    return {
      list: [],
      title: "",
      content: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { data } = await submit("/api/document/getList", {
        PageSize: 1000,
        CurrentPage: 1,
      });
      this.list = data.datas;
      const first = this.list[0];
      this.showDoc(first);
    },
    showDoc(doc) {
      this.title = doc.Title;
      this.content = doc.Content;
      for (const item of this.list) {
        item.class = item == doc ? "title active" : "title";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
  line-height: 40px;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  cursor: pointer;
  height: 40px;
  color: #555;
  display: block;
  font-size: 12px;
  text-decoration: none;
  padding-left: 25px;
  box-sizing: border-box;
  border-left: 2px solid #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title:hover {
  color: blue;
}

.active {
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  list-style: none;
  line-height: 40px;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  cursor: pointer;
  height: 40px;
  color: #555;
  display: block;
  font-size: 12px;
  text-decoration: none;
  padding-left: 25px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ecf4ff;
  border-left: 2px solid #1979ff;
}
</style>
